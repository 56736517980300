<ng-container *transloco="let t; read: 'side-nav'">
  @if (accountService.currentUser$ | async; as user) {
    <div class="side-nav" [ngClass]="{'closed' : (navService.sideNavCollapsed$ | async),
                                      'hidden': (navService.sideNavVisibility$ | async) === false,
                                      'no-donate': (accountService.hasValidLicense$ | async) === true}">

      <app-side-nav-item icon="fa-home" [title]="t('home')" link="/home/"></app-side-nav-item>

      @if (navStreams$ | async; as streams) {
        @if (showAll) {
          <app-side-nav-item icon="fa fa-chevron-left" [title]="t('back')" (click)="showLess()"></app-side-nav-item>
          <app-side-nav-item icon="fa-cogs" [title]="t('customize')" link="/settings" [fragment]="SettingsTabId.Customize"></app-side-nav-item>
          @if (streams.length > ItemLimit && (navService.sideNavCollapsed$ | async) === false) {
            <div class="mb-2 mt-3 ms-2 me-2">
              <label for="filter" class="form-label visually-hidden">{{t('filter-label')}}</label>
              <div class="form-group">
                <input id="filter" autocomplete="off" class="form-control" [(ngModel)]="filterQuery" type="text" aria-describedby="reset-input">
                <button type="button" [attr.aria-label]="t('clear')" class="btn-close" id="reset-input" (click)="filterQuery = '';"></button>
              </div>
            </div>
          }

        }

        @for (navStream of streams | filter: filterLibrary; track navStream.name + navStream.order) {
          @switch (navStream.streamType) {
            @case (SideNavStreamType.Library) {
              <app-side-nav-item [link]="'/library/' + navStream.libraryId + '/'"
                                 [icon]="getLibraryTypeIcon(navStream.library!.type)" [imageUrl]="getLibraryImage(navStream.library!)" [title]="navStream.library!.name" [comparisonMethod]="'startsWith'">
                <ng-container actions>
                  <app-card-actionables [actions]="actions" [labelBy]="navStream.name" iconClass="fa-ellipsis-v"
                                        (actionHandler)="performAction($event, navStream.library!)"></app-card-actionables>
                </ng-container>
              </app-side-nav-item>
            }

            @case (SideNavStreamType.AllSeries) {
              <app-side-nav-item icon="fa-regular fa-rectangle-list" [title]="t('all-series')" link="/all-series/"></app-side-nav-item>
            }

            @case (SideNavStreamType.Bookmarks) {
              <app-side-nav-item icon="fa-bookmark" [title]="t('bookmarks')" link="/bookmarks/"></app-side-nav-item>
            }

            @case (SideNavStreamType.ReadingLists) {
              <app-side-nav-item icon="fa-list-ol" [title]="t('reading-lists')" link="/lists/"></app-side-nav-item>
            }

            @case (SideNavStreamType.Collections) {
              <app-side-nav-item icon="fa-list" [title]="t('collections')" link="/collections/"></app-side-nav-item>
            }

            @case (SideNavStreamType.WantToRead) {
              <app-side-nav-item icon="fa-star" [title]="t('want-to-read')" link="/want-to-read/"></app-side-nav-item>
            }

            @case (SideNavStreamType.SmartFilter) {
              <app-side-nav-item icon="fa-bars-staggered" [title]="navStream.name" link="/all-series" [queryParams]="navStream.smartFilterEncoded"></app-side-nav-item>
            }

            @case (SideNavStreamType.ExternalSource) {
              <app-side-nav-item icon="fa-server" [title]="navStream.name" [link]="navStream.externalSource.host + 'login?apiKey=' + navStream.externalSource.apiKey" [external]="true"></app-side-nav-item>
            }

          }
        }

        @if (totalSize > ItemLimit && !showAll) {
          <app-side-nav-item icon="fa fa-chevron-right" [title]="t('more')" (click)="showMore()"></app-side-nav-item>
        }
      }
    </div>
  }

  <div class="side-nav-overlay" (click)="toggleNavBar()" [ngClass]="{'closed' : (navService.sideNavCollapsed$ | async)}"></div>
  <div class="bottom" [ngClass]="{'closed' : (navService.sideNavCollapsed$ | async),
      'hidden': (navService.sideNavVisibility$ | async) === false || (accountService.hasValidLicense$ | async) === true}">
    @if ((accountService.hasValidLicense$ | async) === false) {
      <app-side-nav-item [ngClass]="'donate'" icon="fa-heart"
                         [ngbTooltip]="t('donate-tooltip')"
                         [link]="WikiLink.Donation"
                         [external]="true"></app-side-nav-item>
    }
  </div>
</ng-container>
